import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.manage.macro.edit');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

// code editor
import { DomSanitizer } from '@angular/platform-browser';
import { KeyMod, KeyCode } from 'monaco-editor';

@Component({
    selector: 'wiz-page-manage-macro-edit',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig_admin/project/main/build/src/app/page.manage.macro.edit/view.scss */
.container {
  overflow: auto;
}

.btn-border {
  border-radius: 10px;
}

.box-shadow {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}

.btn-list {
  border-color: #9EA0AB;
}

.btn-save {
  background: #6758F0;
  color: white;
  border-radius: 10px;
  border: 1px solid #6758F0;
}

.btn-remove {
  background: red;
  color: white;
  border-radius: 10px;
  border: 1px solid red;
}

.info-form {
  padding: 12px 0;
  border-bottom: 1px solid rgba(110, 117, 130, 0.2);
}
.info-form input {
  border-radius: 0;
}
.info-form h4 {
  margin-top: 9px;
}

.info-form.first-child {
  border-top: 2px solid #000000;
}

.loading-wrap {
  display: flex;
  position: absolute;
  inset: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.08);
}

.wiz-content-page {
  flex-direction: row;
  margin: auto;
}
.wiz-content-page .eform-content {
  flex: 1;
  overflow: auto;
  display: flex;
  height: 1200px;
}
.wiz-content-page .eform-content .eform-editor {
  min-width: 860px;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.wiz-content-page .eform-content .eform-editor:last-child {
  border-right: none;
}
.wiz-content-page .eform-content .eform-editor.active {
  opacity: 1;
}
.wiz-content-page .eform-content .eform-editor .test {
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
  height: auto;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-header {
  font-size: 20px;
  font-family: "main-eb";
  line-height: 1;
  display: flex;
  background-color: #B9B9DD;
  color: #fff;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-header .eform-editor-title {
  padding: 4px 8px;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-header .eform-editor-btn {
  padding: 4px 8px;
  cursor: pointer;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-header .eform-editor-btn:hover {
  background-color: #000;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-subheader {
  font-family: "main-eb";
  display: flex;
  background-color: #B9B9DD;
  border-bottom: 1px solid var(--wc-border);
  line-height: 1;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-subheader .eform-editor-tab {
  cursor: pointer;
  color: #fff;
  padding: 5px 12px;
  padding-top: 6px;
  line-height: 1;
  font-size: 12px;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-subheader .eform-editor-tab.active {
  background-color: #fff;
  color: #B9B9DD;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-subheader .eform-editor-tab:hover {
  background-color: #000;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-body {
  flex: 1;
  overflow: auto;
  position: relative;
  background-color: white;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-body .editor-submenu {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  text-align: center;
}

.editor-monaco {
  position: relative;
  width: 100%;
  height: 956px;
  padding-top: 55px;
}

.btn-border {
  border-radius: 10px;
}

.board-table {
  background: white;
}
.board-table td,
.board-table th {
  white-space: nowrap;
  text-align: left;
}
.board-table .width-1 {
  width: 80px;
}
.board-table .width-2 {
  width: 120px;
}
.board-table .width-3 {
  width: 180px;
}
.board-table .width-4 {
  width: 240px;
}
.board-table .width-5 {
  width: 300px;
}
.board-table thead th {
  background-color: #B9B9DD;
  color: black;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 0;
}

.br-10 {
  border-radius: 10px;
}

.editor-preview {
  height: 100%;
  width: 100%;
}

iframe {
  width: 100%;
  height: 1000px;
}`],
})
export class PageManageMacroEditComponent implements OnInit {

    public id;
    public count = 0;
    public info = {
        id: "",
        title: "",
        namespace: "",
        filetype: "",
        content: "",
        code: "",
        created: "",
        updated: ""
    };

    public shortcuts: any = [];
    public src = "info";
    public view = "info";
    public current: any;
    public wiz = {
        pug: "",
        component: "",
        scss: "",
        api: ""
    };
    public namespaceUIBinding: any;

    // 파일 유형 여러개 선택
    public filetypeSelected = [];
    public filetypeChecked = [];
    public checked = "none";

    constructor(@Inject( Service)         public service: Service,@Inject( DomSanitizer    )         private sanitizer: DomSanitizer    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, '/authenticate');
        await this.load();
        this.shortcuts = [
            {
                name: "saveCode",
                key: ["cmd + s", "ctrl + s"],
                monaco: KeyMod.CtrlCmd | KeyCode.KeyS,
                preventDefault: true,
                command: async () => {
                    await this.saveCode();
                }
            },
            {
                name: "close",
                key: ["alt + w"],
                monaco: KeyMod.Alt | KeyCode.KeyW,
                preventDefault: true,
                command: async () => {
                    if (this.activeTab)
                        this.activeTab.close();
                }
            }
        ]
        await this.service.render();
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async load() {
        this.src = 'info';
        this.view = 'info';
        this.id = WizRoute.segment.id;
        let { code, data } = await wiz.call("load", { id: this.id });
        if (code !== 200) return;

        this.info = data.info;
        if (this.info.filetype == null) this.info.filetype = [];
        else this.info.filetype = JSON.parse(this.info.filetype)
        this.wiz = data.info.code;
        if (typeof (this.wiz) == 'string') this.wiz = JSON.parse(this.wiz);

        this.namespaceUIBinding = "portal-macro-" + this.getUIBinding(this.info.namespace);

        await this.service.render();
    }

    public safeUrl(url: string) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    public getUIBinding(namespace: string) {
        if (namespace) {
            return namespace.replace(/\./gim, '-')
        }
        return ""
    }

    public async filetypeSelect(filetype) {
        if (!this.info.filetype.includes(filetype)) {
            this.info.filetype.push(filetype);
        }
        const select = document.querySelector('select');
        select.options[0].selected = true;
        this.checked = "none";
        await this.service.render();
    }

    public async filetypeRemove(filetype) {
        let copy = this.info.filetype;
        this.info.filetype = copy.filter((element) => element !== filetype);
        await this.service.render();
    }

    public async save() {
        let copy = Object.assign({}, this.info)
        copy.filetype = JSON.stringify(copy.filetype)
        let { code } = await wiz.call("save", copy);

        if (code != 200) return;
        let res = await this.alert("저장되었습니다.", 'success');
        if (res) {
            if (WizRoute.segment.id == 'new') {
                await this.service.href(`/macro/edit/${this.info.id}`);
            }
        }
        await this.service.render();
    }

    public async saveCode() {
        let wizString = JSON.stringify(this.wiz);
        let { code } = await wiz.call("saveCode", { "id": this.info.id, "code": wizString, "namespace": this.info.namespace });
        if (code == 200) {
            await this.loading(true);
            await this.service.request.post("https://kmbig-v4.seasonsoft.net/wiz/ide/api/workspace.app.explore/build");
            await this.loading(false);
            this.service.toast.success("저장되었습니다.");
        }
        await this.service.render();
    }


    public async remove() {
        let res = await this.alert("매크로를 삭제하시겠습니까?", "error", "취소");
        if (res) {
            let { code } = await wiz.call("remove", { id: this.info.id });
            if (code != 200) return;
            await this.service.href(`/macro/list`);
            return;
        }
        await this.service.render();
    }

    public close() {
        this.service.href(`/macro/list`);
    }

    public async editorInit(e) {
        for (let i = 0; i < this.shortcuts.length; i++) {
            let shortcut = this.shortcuts[i];
            e.editor.addCommand(shortcut.monaco, shortcut.command);
        }
    }

    public async select(tab) {
        this.src = tab;

        if (this.src == 'info') {
            await this.setView("info");
            this.preview = null;
        } else if (this.src == 'code') {
            await this.setView("pug");
            this.preview = null;
        } else if (this.src == 'preview') {
            await this.setView("preview");
        }

        await this.service.render();
    }

    public async setView(view) {
        this.view = view;
        let monacoopt: any = (language: string) => {
            return {
                language: language,
                renderValidationDecorations: language == 'typescript' ? 'off' : 'on',
                theme: "vs",
                fontSize: 14,
                automaticLayout: true,
                scrollBeyondLastLine: true,
                wordWrap: true,
                roundedSelection: false,
                glyphMargin: false,
                minimap: {
                    enabled: false
                }
            }
        }

        if (view == 'pug') {
            this.current = {
                monaco: monacoopt('pug'),
                data: this.wiz,
                target: 'pug'
            }
        } else if (view == 'component') {
            this.current = {
                monaco: monacoopt('typescript'),
                data: this.wiz,
                target: 'component'
            }
        } else if (view == 'scss') {
            this.current = {
                monaco: monacoopt('scss'),
                data: this.wiz,
                target: 'scss'
            }
        } else if (view == 'api') {
            this.current = {
                monaco: monacoopt('python'),
                data: this.wiz,
                target: 'api'
            }
        }

        await this.service.render();
    }

    private async loading(act) {
        this._loading = act;
        await this.service.render();
    }
}

export default PageManageMacroEditComponent;