import '@angular/compiler';
import { PortalDizestSidebarWorkflowinfoComponent } from './portal.dizest.sidebar.workflowinfo/portal.dizest.sidebar.workflowinfo.component';
import { PortalDizestEditorUimodeComponent } from './portal.dizest.editor.uimode/portal.dizest.editor.uimode.component';
import { PortalDizestSidebarTimerComponent } from './portal.dizest.sidebar.timer/portal.dizest.sidebar.timer.component';
import { PortalDizestEditorImageComponent } from './portal.dizest.editor.image/portal.dizest.editor.image.component';
import { PageManageDatasetListComponent } from './page.manage.dataset.list/page.manage.dataset.list.component';
import { PageWorkflowComponent } from './page.workflow/page.workflow.component';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';
import { PageManageDashboardListComponent } from './page.manage.dashboard.list/page.manage.dashboard.list.component';
import { ComponentManageDatasetDriveComponent } from './component.manage.dataset.drive/component.manage.dataset.drive.component';
import { PageManageDatasetShareViewComponent } from './page.manage.dataset.share.view/page.manage.dataset.share.view.component';
import { PortalDizestEditorSettingComponent } from './portal.dizest.editor.setting/portal.dizest.editor.setting.component';
import { ComponentCardNotificationComponent } from './component.card.notification/component.card.notification.component';
import { PortalDizestStatuscardComponent } from './portal.dizest.statuscard/portal.dizest.statuscard.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { PortalDizestSidebarPackagesComponent } from './portal.dizest.sidebar.packages/portal.dizest.sidebar.packages.component';
import { PageAuthenticateComponent } from './page.authenticate/page.authenticate.component';
import { ComponentPagenationComponent } from './component.pagenation/component.pagenation.component';
import { PortalDizestCodeflowAppinfoComponent } from './portal.dizest.codeflow.appinfo/portal.dizest.codeflow.appinfo.component';
import { PageManageDatasetShareManageComponent } from './page.manage.dataset.share.manage/page.manage.dataset.share.manage.component';
import { PortalSeasonPagenationComponent } from './portal.season.pagenation/portal.season.pagenation.component';
import { PageUserComponent } from './page.user/page.user.component';
import { ComponentNavigationSubComponent } from './component.navigation.sub/component.navigation.sub.component';
import { PortalDizestEditorWorkflowComponent } from './portal.dizest.editor.workflow/portal.dizest.editor.workflow.component';
import { PageManageDatasetShareFormComponent } from './page.manage.dataset.share.form/page.manage.dataset.share.form.component';
import { PortalDizestCodeflowNoteComponent } from './portal.dizest.codeflow.note/portal.dizest.codeflow.note.component';
import { LayoutWorkflowComponent } from './layout.workflow/layout.workflow.component';
import { ComponentNavigationComponent } from './component.navigation/component.navigation.component';
import { PortalDizestDriveComponent } from './portal.dizest.drive/portal.dizest.drive.component';
import { PortalDizestWidgetWorkflowNodeComponent } from './portal.dizest.widget.workflow.node/portal.dizest.widget.workflow.node.component';
import { PortalDizestEditorNotsupportedComponent } from './portal.dizest.editor.notsupported/portal.dizest.editor.notsupported.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { PortalDizestSidebarCodeflowComponent } from './portal.dizest.sidebar.codeflow/portal.dizest.sidebar.codeflow.component';
import { PageMainComponent } from './page.main/page.main.component';
import { ComponentLoadingHexaComponent } from './component.loading.hexa/component.loading.hexa.component';
import { PortalDizestAppsComponent } from './portal.dizest.apps/portal.dizest.apps.component';
import { PageManageDatasetEditComponent } from './page.manage.dataset.edit/page.manage.dataset.edit.component';
import { ComponentLoadingComponent } from './component.loading/component.loading.component';
import { PortalSeasonLoadingDefaultComponent } from './portal.season.loading.default/portal.season.loading.default.component';
import { LayoutNavbarComponent } from './layout.navbar/layout.navbar.component';
import { PortalDizestEditorTerminalComponent } from './portal.dizest.editor.terminal/portal.dizest.editor.terminal.component';
import { PortalDizestEditorVideoComponent } from './portal.dizest.editor.video/portal.dizest.editor.video.component';
import { LayoutTopnavComponent } from './layout.topnav/layout.topnav.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { PortalUserListComponent } from './portal.user.list/portal.user.list.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { PageManageMacroListComponent } from './page.manage.macro.list/page.manage.macro.list.component';
import { PageManageMacroEditComponent } from './page.manage.macro.edit/page.manage.macro.edit.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PageManageDashboardEditComponent } from './page.manage.dashboard.edit/page.manage.dashboard.edit.component';
import { PortalDizestEditorTextComponent } from './portal.dizest.editor.text/portal.dizest.editor.text.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PortalDizestHealthComponent } from './portal.dizest.health/portal.dizest.health.component';
import { PageMypageComponent } from './page.mypage/page.mypage.component';
import { PageManageDatasetShareComponent } from './page.manage.dataset.share/page.manage.dataset.share.component';
import { MatTreeModule } from '@angular/material/tree';

const INDEX_PAGE = "main";

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { URLPattern } from "urlpattern-polyfill";

let app_routes: Routes = [
    {
        component: LayoutTopnavComponent,
        "children": [
            {
                "path": "dataset/list",
                component: PageManageDatasetListComponent,
                "app_id": "page.manage.dataset.list"
            },
            {
                "path": "dashboard/list",
                component: PageManageDashboardListComponent,
                "app_id": "page.manage.dashboard.list"
            },
            {
                "path": "share/list/view/:id",
                component: PageManageDatasetShareViewComponent,
                "app_id": "page.manage.dataset.share.view"
            },
            {
                "path": "share/manage",
                component: PageManageDatasetShareManageComponent,
                "app_id": "page.manage.dataset.share.manage"
            },
            {
                "path": "user/:mode?",
                component: PageUserComponent,
                "app_id": "page.user"
            },
            {
                "path": "share/form",
                component: PageManageDatasetShareFormComponent,
                "app_id": "page.manage.dataset.share.form"
            },
            {
                "path": "main",
                component: PageMainComponent,
                "app_id": "page.main"
            },
            {
                "path": "dataset/edit/:id",
                component: PageManageDatasetEditComponent,
                "app_id": "page.manage.dataset.edit"
            },
            {
                "path": "macro/list",
                component: PageManageMacroListComponent,
                "app_id": "page.manage.macro.list"
            },
            {
                "path": "macro/edit/:id",
                component: PageManageMacroEditComponent,
                "app_id": "page.manage.macro.edit"
            },
            {
                "path": "dashboard/edit/:category",
                component: PageManageDashboardEditComponent,
                "app_id": "page.manage.dashboard.edit"
            },
            {
                "path": "mypage",
                component: PageMypageComponent,
                "app_id": "page.mypage"
            },
            {
                "path": "share/list/:mode?",
                component: PageManageDatasetShareComponent,
                "app_id": "page.manage.dataset.share"
            }
        ]
    },
    {
        component: LayoutWorkflowComponent,
        "children": [
            {
                "path": "workflow",
                component: PageWorkflowComponent,
                "app_id": "page.workflow"
            }
        ]
    },
    {
        component: LayoutEmptyComponent,
        "children": [
            {
                "path": "authenticate",
                component: PageAuthenticateComponent,
                "app_id": "page.authenticate"
            }
        ]
    }
];

export class RouteInfo {
    public path: any = "";
    public segment: any = {};

    constructor() { }
}

window.WizRoute = new RouteInfo();

let patternMatcher = (pattern: any, url: any) => {
    let urlpath = url.map((x: any) => x.path).join("/");
    let testurl = 'http://test/';
    pattern = '/' + pattern;
    urlpath = testurl + urlpath;
    pattern = new URLPattern({ pathname: pattern });
    pattern = pattern.exec(urlpath)
    if (pattern && pattern.pathname) {
        let posParams = {};
        for (let key in pattern.pathname.groups) {
            if (pattern.pathname.groups[key]) {
                posParams[key] = pattern.pathname.groups[key];
            }
        }
        window.WizRoute.path = url.map((x: any) => x.path).join("/");
        window.WizRoute.segment = posParams;

        return { consumed: url, posParams: posParams };
    }
    return null
}

let routes: Routes = [{
    matcher: (url: any) => {
        for (let i = 0; i < app_routes.length; i++) {
            let layout = app_routes[i];
            let layout_childs = layout.children;
            for (let j = 0; j < layout_childs.length; j++) {
                let child = layout_childs[j];
                let matcher = patternMatcher(child.path, url);
                if (matcher)
                    return null;
            }
        }
        return { consumed: url, posParams: {} };
    },
    redirectTo: INDEX_PAGE
}];

for (let i = 0; i < app_routes.length; i++) {
    let layout = app_routes[i];
    let layout_component = layout.component;
    let layout_childs = layout.children;

    let router: any = {
        path: '',
        component: layout_component,
        children: []
    };

    for (let j = 0; j < layout_childs.length; j++) {
        let child = layout_childs[j];
        router.children.push({
            matcher: (url: any) => {
                let matcher = patternMatcher(child.path, url);
                if (matcher) return matcher;
                return null;
            },
            component: child.component
        });
    }
    routes.push(router);
}

@NgModule({ imports: [RouterModule.forRoot(routes)], exports: [RouterModule] })
export class AppRoutingModule { }