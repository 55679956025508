import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.main');
import { OnInit, Input } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-main',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig_admin/project/main/build/src/app/page.main/view.scss */
.container-full {
  min-width: 1920px;
  height: 100%;
  overflow: auto;
}
.container-full .download .btlr {
  border-top-left-radius: 10px;
}
.container-full .download .btrr {
  border-top-right-radius: 10px;
}
.container-full .download table {
  border-radius: 10px;
}
.container-full .download thead th {
  background-color: #F0F1F9;
  font-size: 14px;
  vertical-align: middle;
  color: #777986;
  border-top: 2px solid #D2D4DA;
}
.container-full .download tbody th:first-child,
.container-full .download tbody td:first-child {
  border-right: 1px solid #D2D4DA;
}
.container-full .download tbody th {
  font-size: 15px;
}
.container-full .download tbody td {
  align-content: center;
  font-size: 20px;
  font-weight: bold;
  background: white;
}
.container-full .share .card {
  cursor: pointer;
  width: 300px;
  height: 212px;
  border-radius: 15px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.container-full .meta .btn {
  width: 100%;
  height: 60px;
  font-size: 20px;
  font-weight: bold;
  border-radius: 15px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.container-full .meta .open {
  border-radius: 15px 15px 0 0;
}
.container-full .meta .card {
  width: 100%;
  border-radius: 0 0 15px 15px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.container-full .meta th {
  background: #EDEEF8;
}
.container-full .meta tr {
  border-right: 1px solid #E6E7E9;
}
.container-full .meta tr:first-child {
  border-top: 1px solid #E6E7E9;
}
.container-full .meta .year th {
  width: 100px;
}

.h1-red {
  color: red;
  font-weight: bold;
  text-align: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.h1-purple {
  color: purple;
  font-weight: bold;
  text-align: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.h1-black {
  color: black;
  font-weight: bold;
  text-align: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}`],
})
export class PageMainComponent implements OnInit {

    public share_ls = [
        { title: "미확인 공유 요청 건수", css: "h1-red", value: "request" },
        { title: "심사 진행 건수", css: "h1-purple", value: "process" },
        { title: "심사 완료 건수", css: "h1-black", value: "allow" },
    ]

    public years = [2020, 2021, 2022, 2023, 2024]

    public metadata: any;

    constructor(@Inject( Service)         public service: Service,@Inject( Router    )         private router: Router    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, '/authenticate');
        await this.load();
        await this.meta();
    }

    public async load() {
        let { code, data } = await wiz.call("load");
        this.role = data.role;

        this.share_ls[0].count = data.request;
        this.share_ls[1].count = data.process;
        this.share_ls[2].count = data.finish;

        await this.service.render();
    }

    public click(category) {
        this.service.href(`/share/list/` + category);

    }

    public async meta() {
        const { code, data } = await wiz.call("meta");
        this.metadata = data;
        await this.service.render();
    }

    public async open(index) {
        this.tab = this.tab === index ? null : index;
        await this.service.render();
    }
}

export default PageMainComponent;