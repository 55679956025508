import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.navigation.sub');
import { OnInit, DoCheck } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';

@Component({
    selector: 'wiz-component-navigation-sub',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig_admin/project/main/build/src/app/component.navigation.sub/view.scss */
.text-color {
  color: #4F40F1 !important;
  font-size: 18px;
  letter-spacing: 0px;
  opacity: 1;
}

.text-default {
  color: #9496A1 !important;
  font-size: 18px;
  letter-spacing: 0px;
  opacity: 1;
}

.active {
  color: #4F40F1 !important;
  font-size: 18px;
  letter-spacing: 0px;
  opacity: 1;
}

.ml-share {
  margin-left: 25%;
}

.ml-user {
  margin-left: 40%;
}

nav.navbar-expand-lg {
  display: flex;
  position: relative;
  max-width: 1920px !important;
  height: auto;
  overflow: hidden;
  flex-direction: column;
  background: #fff;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}
@media (max-width: 991px) {
  nav.navbar-expand-lg {
    display: none;
  }
}
nav.navbar-expand-lg .navbar-brand-container {
  width: 100%;
  display: flex;
  text-align: center;
  background: #fff;
  height: 80px;
}
@media (max-width: 991px) {
  nav.navbar-expand-lg .navbar-brand-container {
    height: 67px;
  }
}
nav.navbar-expand-lg .navbar-brand-container .navbar-brand {
  flex: auto;
}
nav.navbar-expand-lg .navbar-brand-container .navbar-brand img {
  width: 180px;
  padding-top: 22px;
}
@media (max-width: 991px) {
  nav.navbar-expand-lg .navbar-brand-container .navbar-brand img {
    width: 128px;
  }
}
nav.navbar-expand-lg .navbar-brand-container .navbar-toggler {
  align-items: flex-end;
  margin-left: 16px;
  margin-right: 16px;
}
nav.navbar-expand-lg .navbar-collapse {
  width: 100%;
  flex: auto;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: #fff;
}
@media (max-width: 991px) {
  nav.navbar-expand-lg .navbar-collapse {
    height: calc(100% - 64px);
    position: fixed;
    top: 64px;
    left: 0;
    overflow-y: auto;
  }
}
nav.navbar-expand-lg .navbar-collapse .navbar-nav {
  margin: 0;
  font-size: 14px;
  overflow-y: auto;
}
@media (max-width: 991px) {
  nav.navbar-expand-lg .navbar-collapse .navbar-nav {
    display: block;
  }
}
nav.navbar-expand-lg .navbar-collapse .navbar-nav a {
  width: 100%;
}
nav.navbar-expand-lg .navbar-collapse .navbar-nav .nav-item {
  cursor: pointer;
  font-family: "main-r";
  margin-left: 24px;
}
@media (max-width: 991px) {
  nav.navbar-expand-lg .navbar-collapse .navbar-nav .nav-item {
    margin-left: 0;
  }
}
nav.navbar-expand-lg .navbar-collapse .navbar-nav .nav-item .nav-link {
  font-size: 18px;
}
nav.navbar-expand-lg .navbar-collapse .navbar-nav .nav-item .nav-link:hover {
  color: var(--wc-blue);
}
nav.navbar-expand-lg .navbar-collapse .navbar-nav .nav-item.active {
  background-color: var(--wc-background);
  border-radius: 10px 0 0 10px;
}`],
})
export class ComponentNavigationSubComponent implements OnInit, DoCheck {

    public share_ls = [
        { url: "/share/list", name: "공유 심사" },
        { url: "/share/manage", name: "공유 로그" },
        { url: "/share/form", name: "가명 정보 동의서" }
    ];

    public log_ls = [
        { url: "/user/list", name: "사용자 관리" },
        { url: "/user/role", name: "권한 변경 로그" },
        { url: "/user/access", name: "접속 로그" }
    ];

    constructor(@Inject( Service)         public service: Service,@Inject( ActivatedRoute)         public route: ActivatedRoute,@Inject( Router)         public router: Router,    ) { }

    public currentRoute: any;
    public previousRoute: any;

    public async ngOnInit() {
        await this.service.init();
        this.currentRoute = this.router.url;
        this.menu = this.currentRoute.split("/")[1];
        console.log(this.menu)
        this.userName = this.service.auth.session.name;
        this.userRole = this.service.auth.session.role;
        await this.service.render();
    }

    public async ngDoCheck() {
        this.currentRoute = this.router.url;
        if (this.currentRoute !== this.previousRoute) {
            this.previousRoute = this.currentRoute;
            this.menu = this.currentRoute.split("/")[1];
            await this.service.render();
        }
    }
}

export default ComponentNavigationSubComponent;