import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.manage.dataset.share.view');
import { Service } from "src/libs/portal/season/service";
import { Router, Event, NavigationEnd } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import toastr from 'toastr';

@Component({
    selector: 'wiz-page-manage-dataset-share-view',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig_admin/project/main/build/src/app/page.manage.dataset.share.view/view.scss */
.btlr {
  border-top-left-radius: 10px;
}

.btrr {
  border-top-right-radius: 10px;
}

.box-shadow {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}

.btn-form {
  border: none;
  color: #4F40F1;
  background-color: white;
}

.btn-request {
  background-color: #E2E4F0;
  color: var(--wiz-color-navy);
  border-radius: 10px;
  border: 1px solid #E2E4F0;
  font-weight: bold;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}

.list-btn {
  border-color: #9EA0AB;
}

.btn-data {
  border-color: #8378E9;
  color: #4F41F1;
}

.btn-allow {
  background-color: #3FBE7A;
  color: white;
  border-radius: 10px;
  border: 1px solid #3FBE7A;
  font-weight: bold;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}

.btn-reject {
  background-color: #ED4264;
  color: white;
  border-radius: 10px;
  border: 1px solid #ED4264;
  font-weight: bold;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}

.btn-process {
  background-color: #C9C5F0;
  color: black;
  border-radius: 10px;
  border: 1px solid #C9C5F0;
  font-weight: bold;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}

.btn-border {
  background-color: white;
  border-radius: 10px;
  font-weight: bold;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}

.btn-full {
  background-color: #B0B0E8;
  color: white;
  border-radius: 10px;
  border: 1px solid #B0B0E8;
  font-weight: bold;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}

.info-form {
  padding: 12px 0;
  border-bottom: 1px solid rgba(110, 117, 130, 0.2);
}

.pre {
  white-space: pre;
}

.loading-wrap {
  display: flex;
  z-index: 1000000;
  position: absolute;
  inset: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.08);
}

.table tr td {
  border-right: 1px solid #D2D4DA;
}
.table tr th {
  border-right: 1px solid #D2D4DA;
  border-top: 1px solid #D2D4DA;
}
.table tr td:first-child {
  border-left: 1px solid #D2D4DA;
}
.table tr th:first-child {
  border-left: 1px solid #D2D4DA;
}
.table td {
  width: 16.6%;
}

.btn-five {
  background-color: #F0142F;
  color: white;
  border-radius: 50%;
  border: 1px solid #F0142F;
  font-weight: bold;
  pointer-events: none;
  box-shadow: 0 3px 6px darkslategray;
  width: 100px;
  height: 100px;
  font-size: 1.5rem;
}

.btn-four {
  background-color: #F43F18;
  color: white;
  border-radius: 50%;
  border: 1px solid #F43F18;
  font-weight: bold;
  pointer-events: none;
  box-shadow: 0 3px 6px darkslategray;
  width: 100px;
  height: 100px;
  font-size: 1.5rem;
}

.btn-three {
  background-color: #F9623E;
  color: white;
  border-radius: 50%;
  border: 1px solid #F9623E;
  font-weight: bold;
  pointer-events: none;
  box-shadow: 0 3px 6px darkslategray;
  width: 100px;
  height: 100px;
  font-size: 1.5rem;
}

.btn-two {
  background-color: #FFBA00;
  color: white;
  border-radius: 50%;
  border: 1px solid #FFBA00;
  font-weight: bold;
  pointer-events: none;
  box-shadow: 0 3px 6px darkslategray;
  width: 100px;
  height: 100px;
  font-size: 1.5rem;
}

.btn-one {
  background-color: #64CF7C;
  color: white;
  border-radius: 50%;
  border: 1px solid #64CF7C;
  font-weight: bold;
  pointer-events: none;
  box-shadow: 0 3px 6px darkslategray;
  width: 100px;
  height: 100px;
  font-size: 1.5rem;
}

.nav-tabs.nav-fill .nav-link {
  cursor: pointer;
  background: #F7F7F8;
  color: #777986;
  font-size: 20px;
}
.nav-tabs.nav-fill .nav-link.active {
  border-bottom: solid 2px #4F41F1;
  background: white;
  color: #4F41F1;
  font-size: 20px;
  text-align: center;
}

.survey .btn {
  border-radius: 0;
}
.survey .container-tab {
  width: 100%;
  padding: 0;
  overflow-x: auto;
}
.survey .nav-tabs {
  align-items: center;
}
.survey .nav-link {
  width: fit-content;
}
.survey .check {
  color: white;
  border-bottom: 1px solid #6967E8;
  background-color: #C9C5F0;
}

.offcanvas {
  width: auto;
  max-width: 32%;
}

iframe {
  min-height: 1000px;
}`],
})
export class PageManageDatasetShareViewComponent implements OnInit {

    public docId: any;
    public info = {
        id: "",
        user_id: "",
        user: "",
        category: "",
        title: "",
        dataset_id: "",
        dataset_name: "",
        purpose: "",
        status: "",
        status_class: "",
        status_name: "",
        created: "",
        updated: "",
        deadline: ""
    };

    public preview = {
        df: "",
        graph: "",
        schema: "",
        filter: "",
    }

    public tab = "info";
    public add = false;
    public expertSelected = []; // 선택된 전문가 리스트 -> id만 (이미 선택된 전문가 다시 선택되지 않게 하기 위함)
    public expertChecked = []; // 선택된 전문가 리스트 -> id, name, email 포함
    public experts = []
    public checked = "default";

    public period = [];
    public sex = ["여자", "남자"];
    public age = ["20-", "20-29", "30-39", "40-49", "50-59", "60+"];
    public weight1: any;
    public weight2: any;
    public height1: any;
    public height2: any;
    public filter = {
        period: {},
        age: {},
        sex: {},
        height1: "",
        height2: "",
        weight1: "",
        weight2: "",
    };
    public deadlineMin: any;

    public conditions = [
        { title: "신장 소숫점 자리 제거", en: "point" },
        { title: "몸무게 소숫점 자리 제거", en: "mmi" },
        { title: "성별 제거", en: "sex" },
        { title: "연령대 제거", en: "agegroup" },
        { title: "신장 제거", en: "height" },
        { title: "몸무게 제거", en: "weight" }]
    public conFilter = {
        point: "", sex: "", agegroup: "", height: "", weight: "", mmi: ""
    };
    public detail: any = [];

    public ruk = {
        ut_num: "", ut_class: "", rs_num: "", rs_class: "", anonymity: ""
    }

    constructor(@Inject( Service)         public service: Service,@Inject( Router)         private router: Router,@Inject( DomSanitizer)         private sanitizer: DomSanitizer,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, '/authenticate');
        await this.title();
        await this.loading(true);
        await this.load();
        await this.pdf();
        await this.loading(false);

        await this.service.render();

        this.router.events.subscribe(async (event: Event) => {
            if (event instanceof NavigationEnd) {
                this.currentRoute = event.url;
                if (this.currentRoute.includes("/share/user/form/view")) {
                    await this.loading(true);
                    await this.load();
                    await this.pdf();
                    await this.loading(false);
                }
            }
        })
        await this.service.render();
    }

    public PDF = '';
    public previewFile: any;

    public async pdf() {
        if (this.previewFile == 'none') {
            this.PDF = false;
        } else {
            let url = wiz.url('pdfPreview');
            url = `${url}?docId=${this.docId}&file=${this.previewFile}`;
            let fullUrl = `https://admin.kmbig-v4.seasonsoft.net${url}`
            this.PDF = this.sanitizer.bypassSecurityTrustResourceUrl(fullUrl);
        }
        await this.service.render();
    }

    public titles: any;
    public surName: any;

    public async title() {
        this.docId = WizRoute.segment.id;
        const { data } = await wiz.call("titles", { id: this.docId });
        this.titles = data.titles
        let req = data.require
        if (req !== 'lifelog' && req !== 'survey') {
            this.surName = 'none';
        } else this.surName = this.titles[0];
        await this.service.render();
    }

    public async surveyInfo(name) {
        this.surName = name;
        await this.service.render();

        await this.loading(true);
        const { code, data } = await wiz.call("surveyInfo", { name: this.surName, id: this.docId });
        this.preview = data
        await this.service.render();
        await this.loading(false);

        this.columns = [];
        for (let value of this.preview.df) {
            for (let key in value) {
                if (!this.columns.includes(key)) {
                    this.columns.push(key);
                }
            }
        }
        await this.service.render();

        if (this.preview.df.length == 0) {
            this.previewTF = false;
        }

    }

    public open = false;

    public async change(tab) {
        this.tab = tab;
        this.service.render();
    }

    public async dataopen() {
        this.open = true;
        await this.service.render();
    }

    public async close() {
        this.open = false;
        this.service.render();
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }
    public datatype: any;

    public async load() {
        this.deadlineMin = new Date().toISOString().slice(0, 10);
        let { code, data } = await wiz.call("load", { id: this.docId, surName: this.surName });
        this.info = data.info;
        this.preview = data.preview;
        this.datatype = this.preview['datatype']
        this.period = JSON.parse(this.preview['period'])
        this.previewFile = this.preview['file']
        this.info.created = this.info.created.substring(0, 10);
        if (this.info.filter) {
            this.filter = JSON.parse(this.info.filter.replace(/'/g, '"').replace(/True/g, 'true').replace(/False/g, 'false'))
        }
        if (this.info.condition) {
            this.conFilter = JSON.parse(this.info.condition.replace(/'/g, '"').replace(/True/g, 'true').replace(/False/g, 'false'));
        };
        this.expertAll = this.info.expertAll;
        this.experts = this.info.expertJudge;

        this.expertAll = this.expertAll.filter(expertAllItem => {
            return !this.experts.some(expert => parseInt(expert.expert_id) === expertAllItem.id);
        });
        this.preview.schema = JSON.parse(data.preview.schema)
        this.previewTF = true;

        this.columns = [];


        if (this.preview.df == undefined) {
            this.previewTF = false;
        } else {
            for (let value of this.preview.df) {
                for (let key in value) {
                    if (!this.columns.includes(key)) {
                        this.columns.push(key);
                    }
                }
            }

            if (this.datatype == 'device') {
                this.detail = this.columns.map((col) => {
                    let item = this.preview.schema.find((item) => item.name === col);
                    return item?.desc || "";
                });
            }
        }


        this.ruk = data.ruk;

        await this.service.render();
    }

    // 데이터셋 정보(상세 설명) 페이지 이동
    public async open() {
        this.url = "https://kmbig-v4.seasonsoft.net/dashboard/device/" + this.info.dataset_id;
        window.open(this.url)
    }

    // 전문가 지정
    public async expertSelect(expert) {
        console.log(this.expertSelected)
        console.log("선태ㅔㄱ:", expert)
        if (!this.expertSelected.includes(expert)) {
            for (let one of this.expertAll) {
                this.expertDic = {}
                if (one.id == expert) {
                    this.expertDic.name = one.name;
                    this.expertDic.email = one.email;
                    this.expertDic.expert_id = one.id;
                    // this.experts.push(this.expertDic);
                    this.expertSelected.push(expert)
                    this.expertChecked.push(this.expertDic)
                    break;
                }
            }
        } else {
            toastr.options = { "positionClass": "toast-bottom-center" };
            toastr.error("이미 지정된 전문가입니다. 다시 지정해주세요.");
        }
        const select = document.querySelector('select');
        select.options[0].selected = true;
        this.checked = "default";
        await this.service.render();
    }

    public async expertRemove(expert) {
        let index = this.experts.indexOf(expert);
        // this.experts.splice(index, 1)
        this.expertSelected.splice(index, 1)
        this.expertChecked.splice(index, 1)
        await this.service.render();
    }

    // 전문가 심사 요청
    public async expertRequest() {
        // if (!this.experts || this.experts.length == 0) {
        if (!this.expertChecked || this.expertChecked.length == 0) {
            await this.alert("전문가 위원회를 지정하지 않았습니다.")
            return;
        }
        // let copy = JSON.stringify(this.experts);
        let copy = JSON.stringify(this.expertChecked);
        let res = await this.alert("공유 요청 필터를 적용했는지 확인해주세요. 해당 전문가 위원회에 심사를 요청하시겠습니까?", "success", "취소")
        if (res) {
            await this.loading(true);
            let { code, data } = await wiz.call("request", { experts: copy, doc: this.info.id, dataset: this.info.dataset_name, deadline: this.info.deadline });
            if (code == 200) {
                await this.loading(false);
                location.reload();
            }
        }
    }

    // 전문가 심사 요청 이후, 전문가 추가
    public async expertAdd() {
        this.add = true;
        await this.service.render();
    }

    // 전문가 추가 취소
    public async expertCancel() {
        this.add = false;
        await this.service.render();
    }

    // 공유 활용 거절
    public async reject() {
        for (let expert of this.experts) {
            if (expert.status == "process") {
                let res = await this.alert("현재 전문가 위원회가 심사중입니다. 정말 " + this.info.dataset_name + " 활용 요청을 거절하시겠습니까?", "error", "취소");
                if (res) {
                    await this.loading(true);
                    let copy = JSON.stringify(this.experts);
                    if (this.datatype == 'device') {
                        let { code, data } = await wiz.call("update", { filename: this.info.dataset_name, experts: copy, doc: this.info.id, status: "reject" });
                        if (code == 200) {
                            await this.loading(false);
                            location.reload();
                            return;
                        }
                    } else if (this.datatype == 'survey') {
                        await this.log('reject');
                        await this.loading(false);
                        location.reload();
                    }
                } else {
                    return;
                }
            }
        }

        if (this.experts || this.experts.length > 0) {
            let res = await this.alert(this.info.dataset_name + " 활용 요청을 거절하시겠습니까?", "error", "취소");
            if (res) {
                await this.loading(true);
                if (this.datatype == 'device') {
                    let { code, data } = await wiz.call("update", {  filename: this.info.dataset_name, doc: this.info.id, status: "reject" });
                    if (code == 200) {
                        await this.loading(false);
                        location.reload();
                        return;
                    }
                } else if (this.datatype == 'survey') {
                    await this.log('reject');
                    await this.loading(false);
                    location.reload();
                }
            }
        }
    }

    // 공유 활용 승인
    public async allow() {
        for (let expert of this.experts) {
            if (expert.status == "process") {
                let res = await this.alert("현재 전문가 위원회가 심사중입니다. 정말 " + this.info.dataset_name + " 활용 요청을 승인하시겠습니까?", "error", "취소");
                if (res) {
                    let copy = JSON.stringify(this.experts);
                    let filtering = JSON.stringify(this.preview.filter)
                    await this.loading(true);
                    if (this.datatype == 'device') {
                        let { code, data } = await wiz.call("update", { filename: this.info.dataset_name, experts: copy, doc: this.info.id, condition: this.preview.condition, filtering: filtering, status: "allow" });
                        if (code == 200) {
                            await this.loading(false);
                            location.reload();
                            return;
                        }
                    } else if (this.datatype == 'survey') {
                        await this.log("allow")
                        await this.loading(false);
                        location.reload();
                        const { code, data } = await wiz.call("updateSur", { experts: copy, datatype: this.datatype, doc: this.info.id, filtering: filtering, status: "allow", titles: JSON.stringify(this.titles), condition: this.preview.condition });
                    }
                } else {
                    return;
                }
            }
        }

        if (this.experts || this.experts.length > 0) {
            let res = await this.alert(this.info.dataset_name + " 활용 요청을 승인하시겠습니까?", "success", "취소");
            if (res) {
                await this.loading(true);
                let copy = JSON.stringify(this.preview.df)
                let filtering = JSON.stringify(this.preview.filter)
                if (this.datatype == 'device') {
                    let { code, data } = await wiz.call("update", {  filename: this.info.dataset_name, doc: this.info.id, df: copy, condition: this.preview.condition, filtering: filtering, status: "allow" });
                    if (code == 200) {
                        await this.loading(false);
                        location.reload();
                        return;
                    }
                } else if (this.datatype == 'survey') {
                    await this.log("allow")
                    await this.loading(false);
                    const { code, data } = await wiz.call("updateSur", { experts: copy, datatype: this.datatype, doc: this.info.id, filtering: filtering, status: "allow", titles: JSON.stringify(this.titles), condition: this.preview.condition });
                    location.reload();
                }
            }
        }
    }

    public async log(status) {
        const { code, data } = await wiz.call("logSur", { datatype: this.datatype, doc: this.info.id, status: status });
    }

    public async deviceExtract() {
        await this.loading(true)
        await wiz.call("play")
        // await this.alert("기기 데이터 추출", "success")
        await this.loading(false)
    }

    public async goDizest() {
        this.url = "/workflow";
        window.open(this.url)
    }

    private async loading(act) {
        this._loading = act;
        await this.service.render();
    }


    public async downloadF(docID) {
        let download = wiz.url("downloadF?docID=" + docID)
        window.open(download, '_blank');
        // window.location.href = download; // 새창열기 없이 다운로드
    }


}

export default PageManageDatasetShareViewComponent;